.nav-item {
  composes: relative flex from global;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  width: 100%;
}

.icon {
  flex: none;
  margin: 4px 12px 0 0;
}

.nav-item-copy {
  composes: text-black-50 from global;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.label-container {
  composes: relative from global;
}

.loading {
  visibility: hidden;
}

.loader {
  composes: absolute top-0 right-0 bottom-0 left-0 from global;
}
