@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.values.css';

.panel {
  composes: absolute top-0 left-0 flex flex-col from global;
  min-height: 100%;
  width: 100%;
  z-index: inherit;
  transform: translateY(-100%);
  transition: transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.panel-visible {
  transform: translateY(0);

  & .top {
    box-shadow: 0px 200vh 0px 200vh rgb(0 0 0 / 40%);
  }
}

/*** TOP OF PANEL ***/

.button-reset {
  background: none;
  border: none;
  cursor: pointer;
}

.top {
  composes: py-3 bg-white from global;
  box-shadow: 0px 200vh 0px 200vh rgb(0 0 0 / 0%);
  transition: box-shadow 300ms linear;

  @media mBreakpointSm {
    padding: 46px 0 34px;
  }
}

.top-header {
  composes: flex items-center justify-between from global;
  margin-bottom: 1.5rem;
}

.prompt {
  color: var(--c-black-50);
  font-size: 10px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 1.5px;
}

.close-container {
  composes: flex from global;
  composes: button-reset;
  font-size: 16px;
  align-items: center;
}

.close-label {
  display: none;
  border-bottom: 2px solid var(--c-black-100);

  @media mBreakpointSm {
    display: inline;
  }
}

.close-icon {
  composes: inline from global;
  height: 12px;
  @media mBreakpointSm {
    display: none;
  }
}

.form {
  composes: flex flex-col from global;

  @media mBreakpointSm {
    flex-direction: row;
  }
}

.search-input-container {
  composes: flex flex-auto items-center pt-2.5 pb-1.5 sm-pt-0 sm-pb-0 from global;
  border-bottom: 1px solid var(--c-black-10);

  @media mBreakpointSm {
    border-bottom: none;
  }
}

.search-icon {
  composes: mr-2 from global;
  margin-top: 5px;
  stroke: var(--c-black-100);
  width: auto;
  height: 24px;

  @media mBreakpointSm {
    stroke: var(--c-black-25);
  }
}

.search-input {
  composes: flex-auto from global;
  width: 100%;
  border: none;
  outline: none;
  background: none;
  border-radius: 0;
  padding-right: 10px;

  font-size: 20px;
  line-height: 24px;

  @media mBreakpointSm {
    font-size: 44px;
  }

  /*
  This webkit styling is awkward and not easy to style. We also want deeper interaction
  with the added buttons than just the input. We hide it entirely and add our own components.
  */
  -webkit-appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: var(--c-black-25);
  }
}

.clear {
  composes: mt-1 from global;
  composes: button-reset;
}

/*** BOTTOM OF PANEL ***/

.bottom-container {
  composes: flex flex-col overflow-hidden from global;
  flex-grow: 1;
  border-top: 6px solid var(--c-black-5);
  background-color: #fff;
  transition: opacity 250ms;
  opacity: 1;
  padding-top: 12px;

  @media mBreakpointSm {
    padding-top: 32px;
  }

  &.hide {
    opacity: 0;
  }
}

.bottom-list {
  composes: list-reset m-0 from global;
  overflow-y: scroll;
}

.bottom-container-row {
  &:hover {
    color: var(--c-secondary);
    background: var(--c-black-5);
  }

  @media mBreakpointSm {
    transition: background-color 0.15s ease-out, color 0.15s ease-out;
  }
}

.bottom-container-row-content {
  composes: flex items-center py-1 from global;
  font-size: 18px;
  line-height: 1.78;

  @media mBreakpointSm {
    font-size: 32px;
    line-height: 48px;
  }
}
