.navigation-container-account {
  z-index: 2;
}

.stop-impersonation-link {
  composes: flex justify-start items-center text-black cursor-pointer from global;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 24px;
  background: repeating-linear-gradient(-55deg, #fccc74, #fccc74 10px, #ffda94 10px, #ffda94 20px);

  &:hover {
    background: repeating-linear-gradient(
      -55deg,
      #ffda94,
      #ffda94 10px,
      #fccc74 10px,
      #fccc74 20px
    ) !important;
  }
}
