.mid-section-separator {
  composes: bt-light from global;
  margin: 0 20px;
}

.subheader {
  composes: text-black-50 from global;
  font-size: 14px;
  line-height: 18px;
  padding: 24px 20px 0;
}

.carousel {
  composes: mt-1.5 mb-2 from flex overflow-x-auto snap-x snap-mandatory from global;
}

.carousel-slide {
  composes: flex-none snap-start snap-always from global;
  width: 80%;
  padding-left: 16px;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
}

.article-image-container {
  composes: radius-1 overflow-hidden from global;
  height: 150px;
  width: 100%;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.article-title {
  composes: font-medium text-black mt-1 pb-0.5 from global;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.view-all-articles-link {
  composes: mt-1 mb-2 from global;
}
