.container {
  composes: flex justify-between items-center from global;
  padding: 16px 20px;
  background: #fff8eb;
}

.header {
  composes: font-bold text-black from global;
  font-size: 16px;
  line-height: 24px;
}

.subheader {
  composes: text-black-50 from global;
  font-size: 14px;
  line-height: 16px;
}
