.navigation-container {
  composes: absolute top-0 right-0 bottom-0 left-0 bg-white overflow-y-auto overflow-x-hidden flex flex-col justify-between from global;
  z-index: 1;
  transform: translateX(100%);
  visibility: hidden;
  transition: visibility 0.4s, transform 0.4s cubic-bezier(0.65, 0, 0.35, 1);

  &.active-navigation-container {
    transform: translateX(0%);
    visibility: visible;
  }
}

.overflow-hidden {
  overflow-y: hidden;
}

.navigation-header {
  composes: flex items-center font-bold text-black bb-light cursor-pointer text-left from global;
  padding: 12px 20px;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  & > svg {
    flex: none;
    margin-right: 16px;
  }

  &:active {
    opacity: 0.5;
  }
}
