.top-lists {
  composes: pb-2 from global;
}

.top-list {
  composes: flex items-center mt-2.5 ml-2 from global;

  &:first-of-type {
    margin-top: 12px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.top-list-title {
  composes: font-medium text-black ml-1 from global;
  font-size: 16px;
  line-height: 20px;
}

.top-list-image-container {
  composes: bg-black-5 flex-none radius-1 p-0.5 from global;
  width: 28px;
  height: 28px;
  mix-blend-mode: darken;
}

.top-list-image {
  object-fit: contain;
  mix-blend-mode: inherit;
  height: 20px;
  width: 20px;
}
