.wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
  flex-shrink: 0;
}

.static {
  position: static;
}

.semi-fixed {
  position: sticky;
  transition: transform 250ms;

  &.hidden {
    transform: translateY(calc(-100% - 10px));
  }
}

.header {
  composes: relative from global;
  background: var(--c-white);
  font-size: 16px;
  line-height: 24px;
  z-index: 1;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);

  &.is-impersonating {
    background: repeating-linear-gradient(
      -55deg,
      #fccc74,
      #fccc74 10px,
      #ffda94 10px,
      #ffda94 20px
    );
  }
}

.top-bar {
  composes: flex items-center from global;
  height: 56px;
}

.primary-nav {
  composes: flex-auto from global;
  flex-basis: 100%;
  height: 100%;
  padding: 10px 0;

  /* Shift the nav left to account for the padding on the first item */
  margin-left: -6px;
  margin-right: 6px;
}

.tertiary-nav {
  composes: flex-auto from global;
  flex-basis: 100%;
  height: 100%;
  padding: 10px 0;

  /* Shift the nav right to account for the padding on the final item */
  margin-left: 6px;
  margin-right: -6px;

  & .nav-bar-list {
    justify-content: flex-end;
  }
}

.logo {
  composes: flex items-center from global;
  -webkit-tap-highlight-color: transparent;

  :active {
    opacity: 0.5;
  }
}

.wordmark {
  height: 16px;
}

.nav-bar-list {
  composes: list-reset m-0 flex items-center from global;
  height: 100%;
}

.nav-bar-list-item {
  composes: relative flex items-stretch cursor-pointer from global;
  height: 100%;
  -webkit-tap-highlight-color: transparent;

  &:not(:last-child) {
    margin-right: 8px;
  }

  & a {
    color: inherit;
  }

  & a,
  & button {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;

    &:hover {
      border-radius: 20px;
      background-color: var(--c-black-5);
    }
  }
}

.nav-bar-list-item-badge {
  composes: absolute flex items-center justify-center text-white from global;
  top: -4px;
  right: -4px;
  min-width: 20px;
  height: 20px;
  background-color: var(--c-error);
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 11px;
  line-height: 100%;
}
