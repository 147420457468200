.active-state:active {
  opacity: 0.5;
}

.account-header-container {
  composes: flex items-center justify-between from global;
  padding: 12px 20px 12px;
  -webkit-tap-highlight-color: transparent;
}

.account-button-container {
  composes: flex items-center font-bold text-black cursor-pointer from global;
  composes: active-state;
  font-size: 14px;
  line-height: 24px;
  gap: 8px;
}

.account-link {
  composes: font-bold underline cursor-pointer from global;
  composes: active-state;
  font-size: 14px;
  line-height: 24px;
  color: var(--c-secondary);
  text-underline-offset: 4px;
}

.curation-link {
  composes: b-light radius-2 bg-white p-1 font-bold text-black flex items-center cursor-pointer from global;
  composes: active-state;
  margin: 0 20px 4px;
  font-size: 14px;
  line-height: 20px;
  -webkit-tap-highlight-color: transparent;

  & svg {
    flex: none;
    margin-right: 8px;
  }
}
