.nav-menu-item-outer-container {
  composes: flex justify-start items-center text-black from global;
  padding: 10px 20px;
  width: 100%;

  &:active {
    opacity: 0.5;
  }
}

.nav-menu-item-inner-container {
  composes: flex justify-between items-center from global;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
}

.icon {
  composes: flex-none mr-1.5 from global;
  width: 24px;
  height: 24px;
}

.nav-menu-item-copy {
  composes: text-black-50 from global;
  font-weight: 400;
  font-size: 14px;
}
