.container {
  composes: bg-black-5 mt-1 py-3 from global;
}

.list {
  composes: list-reset from global;

  & + .header {
    margin-top: 24px;
  }
}

.header {
  composes: text-black-50 from global;
  padding: 0 20px 8px;
  font-size: 14px;
  line-height: 18px;
}
