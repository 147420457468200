.verticals-list-container {
  padding: 12px 20px 32px;
}

.verticals-list-header {
  composes: mb-2 from global;
  font-size: 20px;
  line-height: 24px;
}

.verticals-list {
  composes: list-reset from global;
}

.button {
  -webkit-tap-highlight-color: transparent;
}

.vertical-card {
  composes: relative radius-2 bg-black cursor-pointer overflow-hidden py-2.5 px-2 from global;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:active {
    opacity: 0.5;
  }
}

.vertical-card-img {
  composes: absolute top-0 right-0 bottom-0 left-0 from global;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.vertical-label {
  composes: relative text-white font-bold from global;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
}
